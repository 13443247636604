@import "~@edx/brand/paragon/fonts.scss";
@import "~@edx/brand/paragon/variables.scss";
@import "~@openedx/paragon/scss/core/core.scss";
@import "~@edx/brand/paragon/overrides.scss";

@import "~@edx/frontend-component-footer/dist/footer";
@import "~@edx/frontend-component-header/dist/index";

$fa-font-path: "~font-awesome/fonts";
@import "~font-awesome/scss/font-awesome";

body,
#main
{
  font-size: 14px ;
}

.btn,
.form-control,
.dropdown-menu,
.pgn__modal,
.pgn__form-control-floating-label-content,
.pgn__form-label {
  font-size: 14px !important;
}

.btn-md {
  font-size: 16px !important;
}

#post,
#comment,
#reply,
.discussion-comments,
.text-editor,
.post-preview {
  img {
    height: auto;
    max-width: 100%;
  }
}

.text-staff-color {
  color: $warning-700;
}

.outline-staff-color {
  outline: $warning-700 solid 2px;
}

.text-TA-color {
  color: $success-700;
}

.outline-TA-color {
  outline: $success-700 solid 2px;
}

.outline-anonymous {
  outline: $light-400 solid 2px;
}

.font-size-8 {
  font-size: 8px !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.post-footer-icon-dimensions {
  width: 32px !important;
  height: 32px !important;
}

.like-icon-dimensions {
  width: 21px !important;
  height: 23px !important;
}

.follow-icon-dimensions {
  width: 21px !important;
  height: 24px !important;
}

.dropdown-icon-dimensions {
  width: 20px !important;
  height: 21px !important;
}

.post-summary-icons-dimensions {
  height: 16px !important;
  width: 16px !important;
}

.post-summary-comment-count-dimensions {
  height: 15.39px !important;
  width: 15.5px !important;
}

.post-summary-like-dimensions {
  height: 16px !important;
  width: 17px !important;
}

.post-summary-timestamp {
  font-size: 12px !important;
  line-height: 20px !important;
}

.post-summary-card-selected {
  border-right-width: 4px !important;
  border-right-style: solid !important;
}

.my-14px {
  margin-top: 14px;
  margin-bottom: 14px;
}

.my-10px {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mb-14px {
  margin-bottom: 14px;
}

.mr-0\.5 {
  margin-right: 2px;
}

.mb-0\.5 {
  margin-bottom: 2px;
}

.mt-0\.5 {
  margin-top: 2px;
}

.ml-0\.5 {
  margin-left: 2px;
}

.mt-14px {
  margin-top: 14px;
}

.mb-10px {
  margin-bottom: 10px;
}

.mt-10px {
  margin-top: 10px;
}

.mt-17px {
  margin-top: 17px !important;
}

.mr-36px {
  margin-right: 36.6px;
}

.badge-padding {
  padding-top: 1px;
  padding-bottom: 1px
}

.discussion-post:hover {
  background-color: unset !important;
}

.learner > a:hover {
  background-color: $light-300;
}

.py-10px {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py-8px {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.pb-10px {
  padding-bottom: 10px;
}

.pt-10px {
  padding-top: 10px !important;
}

.px-10px {
  padding-left: 10px;
  padding-right: 10px;
}

.py-2px {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.question-icon-size {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.question-icon-position {
  top: 12px;
  left: 14px;
}

.avarat-img-position {
  margin-top: 17px;
  margin-left: 17px;
}

header {
  .logo {
    margin-right: 1rem;

    img {
      height: 1.75rem;
    }
  }
}

#learner-posts-link {
  span[role=heading]:hover {
    text-decoration: underline;
  }
}

.sidebar-desktop-width {
  max-width: 28rem !important;
}

.sidebar-tablet-width {
  max-width: 21rem !important;
}

.sidebar-XL-width {
  min-width: 29rem !important;
}

.filter-menu:focus-visible {
  background-color: #e9e6e4 !important;
}

.border-light-400-2 {
  border: 2px solid $light-400 !important;
  border-width: 2px !important;
}

.border-primary-500-2 {
  border: 2px solid $primary-500 !important;
  border-width: 2px !important;
}

.post-form {
  input[type=checkbox] {
    width: 18px !important;
    height: 18px !important;
  }
  h4 {
    font-size: 16px !important;
  }
}

.pointer-cursor-hover :hover {
  cursor: pointer;
}

.filter-bar:focus-visible,
.filter-bar:focus {
  outline: none;
}

header {
  line-height: 28px;
  font-size: 18px !important;

  .user-dropdown {
    button {
      height: 44px !important;
      font-size: 18px !important;
      font-weight: 400;
      line-height: 28px;
    };

    span {
      font-weight: 500;
      line-height: 24px;
    };
  };

  .container-xl {
    .course-title-lockup {
      font-size: 1.125rem !important;
    };

    .logo {
      margin-top: 2px;
    };
  };

  span:first-child {
    font-size: 14px !important;
    margin-top: 1px !important;
    margin-bottom: -1px !important;
  };
}

#courseTabsNavigation {
  font-size: 18px !important;
  z-index: 3;

  .container-xl {
    font-size: 1.125rem;

    .nav {
      line-height: 28px;
    }

    .nav-item {
      padding-bottom: 8px;
    }
  };
}

.min-content-height {
  min-height: 80vh !important;
}

.header-action-bar {
  z-index: 2 !important;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 15%), 0px 2px 8px rgb(0 0 0 / 15%);
  top: 0;

  .nav-button-group{
    .nav-item:not(:last-child){
      .nav-link {
          border-right: 0;

          @media screen and (max-width: 567px) {
            border-right: solid 1px #e9e6e4;
          }
      }
    }
  }
}

.tox-tinymce-aux {
  z-index: 1 !important;
}

.breadcrumb-menu {
  z-index: 1;
}

.actions-dropdown {
  z-index: 1;
}

.discussion-topic-group:last-of-type .divider {
  display: none;
}

.zindex-5000 {
  z-index: 5000;
}

#paragon-portal-root .pgn__modal-layer {
  z-index: 5000 !important;
}

#iconbutton-tooltip-top {
  z-index: 0;
}

.btn-icon.btn-icon-primary:hover {
  background-color: $light-300 !important;
  color: $primary-500 !important
}


@media only screen and (max-width: 767px) {
  body:not(.tox-force-desktop) .tox .tox-dialog {
    align-self: center;
  }
}

.tox .tox-editor-header {
  z-index: 0 !important;
}

.badge {
  padding: 1px 5px !important;
  font-size: 12px !important;
}

.pgn__checkpoint {
  max-width: 340px !important;
}

.post-card-padding {
  padding: 24px 24px 10px 24px;
}

.hover-card {
  height: 36px !important;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15), 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  max-width: fit-content !important;
  margin-left: auto;
  margin-top: -2.063rem;
  z-index: 1;
  right: 32px;
}

.response-editor-position {
  margin-top: 50px !important;
}

.hover-button:hover {
  background-color: $light-300 !important;
  height: 36px !important;
  border: none !important;
}

.btn-tertiary:hover {
  background-color: $light-300 !important;
}

.nav-button-group {
  .nav-link {
    &:hover {
      background-color: $light-300 !important;
    }
  }

  .nav-link.active,
  .show>.nav-link {
    background-color: $primary-500 !important;
  }
}

.course-tabs-navigation {
  .nav a {
    &:hover {
      background-color: $light-300 !important;;
    }
  }
}

.btn-tertiary:disabled {
  color: $gray-700 !important;
  background-color: transparent !important;
}

.disable-div {
  pointer-events: none;
}

.on-focus:focus-within {
  outline: 2px solid black;
}

.html-loader p:last-child {
  margin-bottom: 0px;
}

code {
  text-wrap: initial !important;
  word-break: break-word !important;
}

.post-card-comment {
  outline: none;

  &:not(:hover),
  &:not(:focus) {
    .hover-card {
      display: none;
    }
  }

  &:hover,
  &:focus-within {
    .hover-card {
      display: flex;
    }
  }

  .pgn__avatar {
    flex-shrink: 0;
  }
}

.spinner-dimensions {
  height: 24px !important;
  width: 24px !important;
}

.MJX-TEX {
  white-space: normal;
}

.font-style {
  font-style: normal !important;
}

.in-context-navbar {
  line-height: 44px;
}

.line-height-24 {
  line-height: 24px !important;
}

.comments-sort {
  margin-bottom: -44px;
  z-index: 0;
}

.comment-line {
  width: calc(100% - 180px);
  line-height: 1;
}

.post-preview,
.discussion-comments {
  blockquote {
    border-left: 2px solid $gray-200;
    margin-left: 1.5rem;
    padding-left: 1rem;
  }
}

.add-comment-btn {
  border: 1px solid $light-300 !important;
}

.icon-size-24 {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.actions-dropdown-item {
  padding: 12px 16px;
  height: 48px !important;
  width: 195px !important
}

.font-xl {
  font-size: 18px !important;
  line-height: 28px !important;
}

.usabilla_live_button_container{
  right: 0px !important;
}

.learning-header{
  z-index: 4;
  position: relative;
  background-color: #fff;
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.author-name {
  line-height: 1.5;
  word-break: break-all;
}

.content-unavailable-desktop {
  width: 32.188rem !important;
}

th, td {
  border: 1px dashed $gray-200;
  padding: 0.4rem;
  white-space: nowrap;
}

.help-icon {
  margin: -47px -3px 0px 0px;
}

.help-icon-size {
  height: 16px !important;
  width: 16px !important;
}

@media only screen and (max-width: 367px) {

  .discussion-comments h5,
  .btn,
  .alert-message-content,
  .pgn__form-control-floating-label-content,
  .form-control,
  .pgn__modal,
  .pgn__form-label,
  .dropdown-menu,
  .tox-tbtn, 
  .tooltip {
    font-size: 10px !important;
  }

  .badge,
  .post-summary-timestamp,
  .hover-card button {
    font-size: 8px !important;
  }

  .header-action-bar button {
    height: 42px;
    text-wrap: nowrap;
    ;
  }

  .nav-item a {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }

  .post-form h4,
  .discussion-posts .btn-md {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 367px) and (max-width: 768px) {

  .discussion-comments h5,
  .btn,
  .alert-message-content,
  .pgn__form-control-floating-label-content,
  .form-control,
  .pgn__form-label,
  .pgn__modal,
  .dropdown-menu,
  .tox-tbtn,
  .tooltip {
    font-size: 12px !important;
  }

  .badge,
  .post-summary-timestamp,
  .hover-card.btn-tertiary {
    font-size: 10px !important;
  }

  .post-form h4,
  .discussion-posts .btn-md {
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 769px) {

  body,
  #main,
  .tooltip {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {

  body,
  #main {
    font-size: 12px;
  }
}

@media only screen and (max-width: 366px) {

  body,
  #main {
    font-size: 10px;
  }
}
